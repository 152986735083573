@use 'src/styles/functions';
@use 'src/styles/colors';
@use 'src/styles/mixins';

.content {
  background: linear-gradient(40.21deg, #171d31 26.1%, #201d2c 79.42%);
  box-shadow: inset 2px 2px 100px rgba(66, 66, 66, 0.1);
  padding: functions.space(1) functions.space(1) functions.space(1) functions.space(3);
  display: flex;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  gap: functions.space(3);

  svg {
    font-size: functions.space(5);
  }
}

.text {
  @include mixins.text-regular;
  flex: 1 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.wrapper {
  padding: 1px;
  background: linear-gradient(-140deg, #ab9ff4 0%, #2a179b 80%);
  border-radius: 4px;
  width: 300px;
  overflow: hidden;
}
