$background: #1a1e2c;
$background-dark: #171a27;
$background-active: #25334d;
$background-hover: #23283a;
$blue: #3e80e3;
$green: #4fa171;
$green600: #23724b;
$red: #c54347;
$purple600: #b17cf3;
$purple400: #8b43ea;
$purple500: #9d5cf1;
$gray50: #e4e7ec;
$gray100: #d0d5dd;
$gray200: #98a2b3;
$gray300: #667085;
$gray400: #25334d;
$gray500: #23283a;
$gray600: #1a1e2c;
$background-green: #7bb895;
$background-red: #e8b4b5;
$background-yellow: #c5a34c;
$text: #e4e7ec;
$text-green: #14372a;
$text-red: #501c13;
$text-yellow: #231d0d;

:export {
  background: $background;
  backgroundDark: $background-dark;
  backgroundActive: $background-active;
  backgroundHover: $background-hover;
  blue: $blue;
  green: $green;
  red: $red;
  purple600: $purple600;
  purple500: $purple500;
  purple400: $purple400;
  gray50: $gray50;
  gray100: $gray100;
  gray200: $gray200;
  gray300: $gray300;
  gray400: $gray400;
  gray500: $gray500;
  gray600: $gray600;
  backgroundGreen: $background-green;
  backgroundRed: $background-red;
  backgroundYellow: $background-yellow;
  text: $text;
  textGreen: $text-green;
  textRed: $text-red;
  textYellow: $text-yellow;
}
