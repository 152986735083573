@use 'src/styles/colors';
@use 'src/styles/mixins';
@import '../TextInput/TextInput.module';

.comet-autocomplete-field {
  .MuiInputBase-root {
    @include mixins.text-regular;
    border: none;
    padding: 0 !important;
  }
  .MuiInputBase-input {
    @extend .input;
  }

  fieldset {
    border: none;
  }

  .MuiAutocomplete-endAdornment {
    display: none;
  }
}
