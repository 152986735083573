@use 'src/styles/functions';
@use 'src/styles/colors';
@use 'src/styles/mixins';

.MuiFormGroup-root {
  .MuiFormControlLabel-label {
    @include mixins.text-regular;
    color: colors.$gray200 !important;

    &.Mui-disabled {
      opacity: 0.4;
    }
  }

  .MuiRadio-root svg {
    font-size: functions.space(4);
    width: functions.space(4);
    height: functions.space(4);
    fill: transparent;
  }
}
