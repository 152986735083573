@use '../../../../styles/functions';
@use '../../../../styles/colors';
@use '../../../../styles/mixins';

.MuiMenu-root {
  .DashboardAlerts__menu {
    background-color: colors.$gray500;
    padding: functions.space(1) 0;
  }

  .DashboardAlerts__paper {
    background-color: colors.$gray500;
    margin-block-start: functions.space(1);
    min-width: functions.space(60);
  }

  .DashboardAlerts__menuItem {
    @include mixins.text-regular;
    color: colors.$gray100;
    padding: functions.space(2) functions.space(5);
    border-bottom: 1px solid colors.$gray600;
    gap: functions.space(1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: default;
    position: relative;

    &--notSeen::after {
      content: '';
      position: absolute;
      inset-block-start: functions.space(4);
      inset-inline-start: functions.space(2);
      width: functions.space(1);
      height: functions.space(1);
      background-color: colors.$red;
      border-radius: 50%;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .DashboardAlerts__menuHeader {
    @extend .DashboardAlerts__menuItem;
    padding: functions.space(2) functions.space(3);
    font-weight: 700;
  }
}
