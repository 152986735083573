@use 'src/styles/functions';
@use 'src/styles/colors';
@use 'src/styles/mixins';

.label {
  @include mixins.text-regular;
  color: colors.$gray50;
  margin-bottom: functions.space(1);
  display: block;
}
