@use '../../../../styles/functions';
@use '../../../../styles/colors';
@use '../../../../styles/mixins';

.badge {
  @include mixins.text-small;
  font-weight: 700;
  padding: 2px functions.space(2);
  border-radius: 1000px;
  transition: all 0.3s ease;
}

.label {
  @include mixins.text-regular;
}

.container {
  display: flex;
  align-items: center;
  gap: functions.space(2);
  margin-inline-end: functions.space(10);
}
