@use 'src/styles/functions';
@use 'src/styles/colors';
@use 'src/styles/mixins';

.container {
  padding: functions.space(4);
  border-radius: 6px;
  background-color: colors.$background-dark;
  min-width: 400px;
  flex: 4 4 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.title {
  @include mixins.gradient-title;
  margin-block-end: functions.space(3);
  width: fit-content;
}

.tables {
  display: flex;
  gap: functions.space(2);
  overflow: hidden;
  flex: 1 1 0;
}

.rowFill {
  position: absolute;
  border-radius: 4px;
  height: 100%;
  z-index: -1;
}

.rowFillGreen {
  @extend .rowFill;
  inset-inline-end: 0;
  inset-block-start: 0;
  background: linear-gradient(270deg, #219464, transparent);
}

.rowFillRed {
  @extend .rowFill;
  inset-inline-start: 0;
  inset-block-start: 0;
  background: linear-gradient(90deg, #a33c2a, transparent);
}
