@use 'src/styles/functions';
@use 'src/styles/colors';
@use 'src/styles/mixins';

.container {
  display: flex;
  align-items: center;
}

.label {
  @include mixins.text-regular;
  margin-inline-start: functions.space(1);
}
