@use 'src/styles/functions';
@use './colors';

@mixin text-gradient-purple-blue {
  background: linear-gradient(90.52deg, #9d5bf0 30%, #3f84ec 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin text-regular {
  font-size: functions.space(3.5);
  line-height: functions.space(5);
  font-weight: 500;
}

@mixin text-medium {
  font-size: functions.space(4);
  line-height: functions.space(6);
  font-weight: 500;
}

@mixin text-small {
  font-size: functions.space(3);
  line-height: functions.space(4);
  font-weight: 400;
}

@mixin button-no-style {
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0;
  outline: none !important;
  background: none;
}

@mixin gradient-title {
  @include text-regular;
  @include text-gradient-purple-blue;
  font-weight: 700;
  text-transform: uppercase;
}
