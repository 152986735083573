@use 'src/styles/functions';
@use 'src/styles/colors';
@use 'src/styles/mixins';

.input {
  @include mixins.text-regular;
  background-color: #292a41;
  box-shadow: inset -1px -1px colors.$gray400, inset 1px 1px colors.$gray400;
  border: none;
  border-radius: 4px;
  padding: functions.space(2) functions.space(3);
  outline: none !important;
  -moz-appearance: textfield;

  &::placeholder {
    @include mixins.text-regular;
    color: colors.$gray300;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
