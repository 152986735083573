@use 'src/styles/functions';
@use 'src/styles/colors';
@use 'src/styles/mixins';

.appWrapper {
  background-color: colors.$background;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.mainWrapper {
  flex-direction: column;
  display: flex;
  align-self: stretch;
  flex: 1 1;
}

.scrollWrapper {
  display: flex;
  flex-direction: column;
}

.loading {
  opacity: 0.5;
}

