@use 'src/styles/functions';
@use 'src/styles/colors';
@use 'src/styles/mixins';
@use 'src/styles/constants';

.container {
  padding: 1px 0;
  background: linear-gradient(-170deg, #ab9ff4 0%, #2a179b 100%);
  backdrop-filter: blur(50px);
  display: flex;
  justify-content: flex-end;
}

.wrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: colors.$background-dark;
  min-height: functions.space(25);
  min-width: 200vw;
  max-width: 200vw;
  overflow: hidden;
}
