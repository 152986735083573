@use '../../styles/functions';
@use '../../styles/colors';
@use '../../styles/mixins';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: functions.space(5) functions.space(4);
}

.logo {
  max-height: functions.space(15);
}

.endWrapper {
  display: flex;
  align-items: center;
}

.checkboxContainer {
  margin-inline-end: functions.space(10);
}

.checkboxLabel {
  color: colors.$gray200;
}

.startWrapper {
  display: flex;
  align-items: center;
}
