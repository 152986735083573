@use 'src/styles/functions';
@use 'src/styles/colors';
@use 'src/styles/mixins';

.mainWrapper {
  margin: functions.space(4) functions.space(4) 0;
  display: flex;
  align-self: stretch;
  flex: 1 1;
}
