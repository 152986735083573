@use 'src/styles/functions';
@use 'src/styles/colors';
@use 'src/styles/mixins';

.container {
  padding: functions.space(3);
  border-radius: 4px;
  background-color: colors.$green600;
}

.title {
  @include mixins.text-regular;
  font-weight: 700;
  margin-block-end: functions.space(2);
}

.description {
  @include mixins.text-regular;
}
