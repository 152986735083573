@use 'src/styles/functions';
@use 'src/styles/colors';
@use 'src/styles/mixins';
@use 'src/styles/constants';

.container {
  background-color: colors.$background-dark;
  padding: functions.space(3) constants.$dashboard-trade-x-padding;
  border-inline-end: 1px solid #373854;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 constants.$dashboard-trade-width;
  overflow: hidden;
  height: 100%;

  &:last-child {
    border-inline-start: 1px solid #373854;
  }
}

.text {
  font-size: functions.space(3.25);
  line-height: functions.space(5);
  font-weight: 700;
  text-transform: uppercase;

  &.positive {
    color: colors.$green;
  }

  &.negative {
    color: colors.$red;
  }
}

.textSmall {
  font-size: functions.space(3.25);
  line-height: functions.space(4.5);

  &.gray {
    color: colors.$gray200;
  }
}

.sellerInfo {
  display: flex;
  gap: functions.space(3);
}
