@use '../../../../styles/functions';
@use '../../../../styles/colors';
@use '../../../../styles/mixins';

.button {
  gap: functions.space(2);

  svg {
    font-size: functions.space(5);
  }
}
