@use 'src/styles/functions';
@use 'src/styles/colors';
@use 'src/styles/mixins';

.header {
  @include mixins.text-regular;
  margin-block-start: functions.space(6);
  color: colors.$gray200;
  padding-bottom: functions.space(2);
  box-shadow: inset 0 -1px colors.$gray200;
}

.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1 1;
}
