@use 'src/styles/functions';
@use 'src/styles/colors';
@use 'src/styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  background-color: colors.$background-dark;
  padding: functions.space(3);
  min-width: 240px;
}

.title {
  @include mixins.gradient-title;
}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search {
  margin: functions.space(2) 0 functions.space(4);
}

.stockWrapper {
  display: flex;
  flex-direction: column;
  gap: functions.space(3);
  flex: 1 1;
  overflow: auto;
}
