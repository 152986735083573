@use 'src/styles/functions';

.app-checkbox {
  .MuiSvgIcon-root {
    font-size: functions.space(4);
    border-radius: 4px;
  }

  &.MuiCheckbox-root {
    padding: functions.space(1);

    &.Mui-checked {
      color: transparent;
    }
  }
}
