@use 'src/styles/functions';
@use 'src/styles/colors';
@use 'src/styles/mixins';

.container {
  padding: functions.space(4);
  border-radius: 6px;
  background-color: colors.$background-dark;
  display: flex;
  overflow: hidden;
}

.titleWrapper {
  display: flex;
  gap: functions.space(2);
  align-items: center;
}

.title {
  font-size: functions.space(6);
  line-height: functions.space(8);
  font-weight: 700;
  text-transform: uppercase;
}

.description {
  @include mixins.text-regular;
  color: colors.$gray200;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-inline-end: functions.space(4);
}

.info {
  flex: 0 1 auto;
  min-width: functions.space(60);
  overflow: hidden;
}

.favouriteIcon {
  font-size: functions.space(5);
}

.checkbox {
  padding: functions.space(1) !important;
}

.arrowIcon {
  font-size: functions.space(3) !important;
}

.price {
  font-size: functions.space(6);
  line-height: functions.space(8);
  font-weight: 400;
}

.change {
  @include mixins.text-regular;
  color: colors.$gray200;
  display: flex;
  align-items: center;
  gap: functions.space(1);
  white-space: nowrap;

  &.positive {
    color: colors.$green;
  }

  &.negative {
    color: colors.$red;
  }
}

.priceWrapper {
  border-inline-end: 1px solid colors.$gray600;
  padding-inline-end: functions.space(4);
  text-align: end;
}

.fieldWrapper {
  padding: functions.space(2) functions.space(4);
}

.fieldLabel {
  @include mixins.text-small;
  color: colors.$gray200;
  text-transform: uppercase;
  white-space: nowrap;
}

.fieldValue {
  @include mixins.text-regular;
  white-space: nowrap;
}
