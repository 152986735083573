@use 'src/styles/functions';
@use 'src/styles/colors';
@use 'src/styles/mixins';

.container {
  @include mixins.text-regular;
  padding: functions.space(4) functions.space(3) functions.space(4) 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: inset 0 -1px colors.$gray500;

  svg {
    font-size: functions.space(4);
    width: functions.space(4);
    height: functions.space(4);
  }
}
