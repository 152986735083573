@use 'src/styles/functions';
@use 'src/styles/colors';
@use 'src/styles/mixins';
@use 'src/styles/constants';

body {
  margin: 0;
  font-family: 'Inter';
  color: colors.$gray50;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html {
  font-style: normal;
  font-size: constants.$base-font-size;
}

#root {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

a {
  color: colors.$purple600;

  &:visited {
    color: colors.$purple400;
  }
}

input,
button {
  color: colors.$gray50;
}

.comet-table {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.scrollable {
    max-height: 100%;
    overflow: auto;
    overflow-x: hidden;
    flex: 1 1;
  }

  .comet-table-body {
    padding-block-start: functions.space(2);
  }

  .comet-table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid colors.$gray500;
    background-color: colors.$background-dark;
  }

  .comet-table-header-cell {
    @include mixins.text-small;
    color: colors.$gray200;
    text-transform: uppercase;
    text-align: center;
    padding: functions.space(1) functions.space(1) functions.space(2);
    flex: 1 1;

    &:first-child {
      text-align: start;
    }

    &:last-child {
      text-align: end;
    }
  }

  .comet-table-row {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
  }

  .comet-table-cell {
    font-size: functions.space(3.25);
    line-height: functions.space(5);
    font-weight: 400;
    z-index: 1;
    text-align: center;
    padding: functions.space(1);
    border: none;
    flex: 1 1;

    &:first-of-type {
      text-align: start;
    }

    &:last-of-type {
      text-align: end;
    }
  }

  .comet-table-spacer {
    height: functions.space(1);
  }

  .comet-table-spacer-2 {
    height: functions.space(2);
  }

  .comet-table-spacer-3 {
    height: functions.space(3);
  }
}

.comet-scrollbar-track {
  position: absolute;
  width: 6px;
  z-index: 3 !important;
  right: 2px;
  bottom: 2px;
  top: 2px;
  border-radius: 3px;
}

.comet-scrollbar-thumb {
  background-color: transparent;
  border-radius: 1000px;
  transition: all 0.2s ease;
  opacity: 0.8;
}

.scrollable-view {
  & > *:first-child {
    &::-webkit-scrollbar, &::-webkit-scrollbar-button, &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track, &::-webkit-scrollbar-corner {
      opacity: 0;
    }
  }

  &:hover .comet-scrollbar-thumb {
    background-color: colors.$gray300;
  }
}

.comet-table-container-scrollable {
  width: auto !important;
  overflow: hidden !important;
}

.text-bold {
  font-weight: 700 !important;
}

.text-align-start {
  text-align: start !important;
}

.text-align-end {
  text-align: end !important;
}

.flex-1 {
  flex: 1 1;
}

.flex-2 {
  flex: 2 2;
}

.flex-3 {
  flex: 3 3;
}

.flex-4 {
  flex: 4 4;
}

.comet-modal .MuiModal-backdrop {
  backdrop-filter: blur(3px);
}

div.MuiAutocomplete-paper {
  @include mixins.text-regular;
  margin-block-start: functions.space(1);
  background-color: colors.$gray500;
  color: colors.$gray200;
}

.notistack-MuiContent.notistack-MuiContent {
  @include mixins.text-regular;
  cursor: pointer;
}
