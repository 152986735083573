@use 'src/styles/functions';
@use 'src/styles/colors';
@use 'src/styles/mixins';

.container {
  width: 100%;
  height: 100%;
  padding: functions.space(5)
}

.scrollableWrapper {
  display: flex;
  flex-direction: column;
}

.formWrapper {
  background: linear-gradient(40.21deg, rgba(17, 39, 119, 0.2) 26.1%, rgba(60, 36, 92, 0.2) 79.42%);
  box-shadow: inset 2px 2px 100px rgba(66, 66, 66, 0.1);
  backdrop-filter: blur(50px);
  padding: functions.space(8);
  display: flex;
  flex-direction: column;
  width: 320px;
  border-radius: 8px;
  align-items: stretch;
  margin: auto;
}

.form {
  display: flex;
  flex-direction: column;
}

.submitButton {
  align-self: stretch;
}

.title {
  font-size: functions.space(6);
  line-height: functions.space(8);
  margin: functions.space(1) 0 functions.space(6);
  text-align: center;
  color: colors.$gray50;
}

.logo {
  max-height: functions.space(13);
  align-self: center;
}

.error {
  color: colors.$red;
  font-size: functions.space(3);
  margin: functions.space(1) 0;
}

.signupWrapper {
  padding-top: functions.space(6);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: functions.space(2);
}

.signupText {
  @include mixins.text-regular;
  color: colors.$gray100;
}

.signupLink {
  @include mixins.text-regular;
  color: inherit;
}

.forgotPassword {
  @include mixins.text-regular;
  text-align: right;
  align-self: flex-end;
  margin-bottom: functions.space(5);
}
