@use 'src/styles/functions';
@use 'src/styles/colors';
@use 'src/styles/mixins';

.eyeButton {
  @include mixins.button-no-style;

  width: functions.space(5);
  height: functions.space(5);

  svg {
    width: functions.space(5);
    height: functions.space(5);
    fill: colors.$gray200;
  }
}
