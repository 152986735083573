@use 'src/styles/functions';
@use 'src/styles/colors';
@use 'src/styles/mixins';

.container {
  position: relative;
  display: flex;
}

.icon {
  position: absolute;
  inset-inline-end: functions.space(3);
  inset-block-start: 50%;
  transform: translateY(-50%);
  font-size: functions.space(5) !important;
  color: colors.$gray300;
}

.input {
  width: 100%;
  flex: 1;
}
