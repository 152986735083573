@use 'src/styles/functions';
@use 'src/styles/colors';
@use 'src/styles/mixins';

.container {
  @include mixins.button-no-style;
  display: flex;
  background: transparent;
  flex: 0 0 functions.space(10);

  &.hidden {
    display: none;
  }
}

.checkboxWrapper {
  align-self: center;
}

.icon {
  font-size: functions.space(3) !important;
}

.border {
  align-self: stretch;
  flex: 0 0 functions.space(1);
  border-radius: 1000px;
  margin-inline-start: functions.space(1);
  color: colors.$gray200;
  background-color: colors.$gray200;

  &.positive {
    background: colors.$green;
  }

  &.negative {
    background: colors.$red;
  }
}

.info {
  flex: 1 1;
  padding: 0 functions.space(2);
  display: flex;
  flex-direction: column;
  gap: functions.space(1);
  align-self: stretch;
  text-align: start;
}

.name {
  @include mixins.text-regular;
  font-weight: 700;
  text-transform: uppercase;
}

.description {
  @include mixins.text-small;
  color: colors.$gray300;
}

.price {
  @include mixins.text-regular;
  margin-inline-end: functions.space(1);
}

.change {
  @include mixins.text-regular;
  font-weight: 400;

  &.positive {
    color: colors.$green;
  }

  &.negative {
    color: colors.$red;
  }
}
