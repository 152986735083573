@use 'src/styles/functions';
@use 'src/styles/colors';
@use 'src/styles/mixins';

.container {
  min-height: functions.space(7);
}

.rowFill {
  position: absolute;
  border-radius: 4px;
  height: 100%;
  z-index: -1;
}

.rowFillGreen {
  @extend .rowFill;
  inset-inline-end: 0;
  inset-block-start: 0;
  background: linear-gradient(270deg, #219464, transparent);
}

.rowFillRed {
  @extend .rowFill;
  inset-inline-start: 0;
  inset-block-start: 0;
  background: linear-gradient(90deg, #a33c2a, transparent);
}
