@use 'src/styles/functions';
@use 'src/styles/colors';
@use 'src/styles/mixins';

.inputsWrapper {
  display: flex;
  gap: functions.space(6);
}

.submitButton {
  align-self: stretch;
}

.error {
  color: colors.$red;
  font-size: functions.space(3);
  margin: functions.space(1) 0;
}

.form {
  display: flex;
  flex-direction: column;
}
