@use 'src/styles/functions';
@use 'src/styles/colors';
@use 'src/styles/mixins';

.container {
  min-height: functions.space(7);
  padding: functions.space(1) 0;
}

.coloredCell {
  color: colors.$gray200 !important;

  &.positive {
    color: colors.$green !important;
  }

  &.negative {
    color: colors.$red !important;
  }
}
