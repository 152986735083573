@use '../../../../styles/functions';
@use '../../../../styles/colors';
@use '../../../../styles/mixins';

.container {
  margin-inline-end: functions.space(2);
  position: relative;

  svg {
    width: functions.space(5);
    height: functions.space(5);
  }

  &.hasNewAlerts::after {
    content: '';
    position: absolute;
    inset-block-start: functions.space(3);
    inset-inline-end: functions.space(3);
    width: functions.space(1);
    height: functions.space(1);
    background-color: colors.$red;
    border-radius: 50%;
  }
}

.emptyText {
  @include mixins.text-regular;
  padding: functions.space(2) functions.space(3);
  font-weight: 400;
  color: colors.$gray200;
}
