@use 'src/styles/functions';
@use 'src/styles/_colors';
@use 'src/styles/_mixins';

.container {
  margin-block-end: functions.space(6);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &.hasError {
    margin-block-end: 0;

    .input {
      box-shadow: inset -1px -1px colors.$red, inset 1px 1px colors.$red, inset -1px 1px colors.$red, inset 1px -1px colors.$red;
    }

    :global(.MuiCheckbox-root) > :global(.MuiSvgIcon-root) {
      box-shadow: inset -1px -1px colors.$red, inset 1px 1px colors.$red, inset -1px 1px colors.$red, inset 1px -1px colors.$red;
    }
  }

  &.hasEndElement {
    .input {
      padding-inline-end: functions.space(9);
    }
  }

  &.hasStartElement {
    .input {
      padding-inline-start: functions.space(9);
    }
  }
}

.inputWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.error {
  color: colors.$red;
  font-size: functions.space(3);
  line-height: 1;
  margin: functions.space(1) 0 functions.space(2);
}

.endElement {
  position: absolute;
  inset-block-start: 50%;
  inset-inline-end: functions.space(3);
  transform: translateY(-50%);
  z-index: 1;
}

.startElement {
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: functions.space(3);
  transform: translateY(-50%);
  z-index: 1;
}

.startElement svg,
.endElement svg {
  font-size: functions.space(4);
  fill: transparent;
}
