@use '../../../../styles/functions';
@use '../../../../styles/colors';
@use '../../../../styles/mixins';

.MuiMenu-root {
  .AccountButton__menu {
    background-color: colors.$gray500;
    padding: functions.space(1);
  }

  .AccountButton__paper {
    background-color: colors.$gray500;
    margin-block-start: functions.space(1);
  }

  .AccountButton__menuItem {
    @include mixins.text-regular;
    color: colors.$gray200;
    margin: 0;
    padding: functions.space(2);
    cursor: pointer;
    border: none;

    &:last-child {
      border-top: 1px solid colors.$gray400;
    }
  }
}
