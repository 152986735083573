@use 'src/styles/functions';
$dashboard-trade-width: functions.space(20);
$dashboard-trade-x-padding: functions.space(10);
$dashboard-trade-carousel-animation-length: 300ms;
$base-font-size: 16px;

:export {
  dashboardTradeWidth: $dashboard-trade-width;
  dashboardTradeXPadding: $dashboard-trade-x-padding;
  dashboardTradeCarouselAnimationLength: $dashboard-trade-carousel-animation-length;
}
