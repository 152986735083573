@use 'src/styles/functions';
@use 'src/styles/colors';
@use 'src/styles/mixins';

.container {
  padding: functions.space(4);
  border-radius: 6px;
  background-color: colors.$background-dark;
  min-width: 400px;
  flex: 3 3 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.title {
  @include mixins.gradient-title;
  margin-block-end: functions.space(3);
  width: fit-content;
}

.coloredCell {
  color: colors.$gray200 !important;

  &.positive {
    color: colors.$green !important;
  }

  &.negative {
    color: colors.$red !important;
  }
}
