@use 'src/styles/functions';
@use 'src/styles/colors';
@use 'src/styles/mixins';

.container {
  margin-inline-start: functions.space(4);
  margin-block-end: functions.space(2);
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.loading {
    opacity: 0.5;
  }
}

.mainWrapper {
  margin-block-start: functions.space(4);
  display: flex;
  overflow: hidden;
  flex: 1 1 0;
  gap: functions.space(4);
  min-height: functions.space(150);
}

.tradingViewWrapper {
  flex: 1;
}

.additionalDataWrapper {
  flex: 0;
  display: flex;
  flex-direction: column;
  gap: functions.space(4);
}
