@use 'src/styles/functions';
@use 'src/styles/colors';
@use 'src/styles/mixins';

.button {
  margin-block-end: functions.space(4);
}

.icon {
  font-size: functions.space(3) !important;
  margin-inline-end: functions.space(2);
}
